import { defineMessages } from "react-intl";

export default defineMessages({
  docs: {
    id: "header.docs",
    defaultMessage: "Documentatie",
  },
  logout: {
    id: "header.logout",
    defaultMessage: "Afmelden",
  },
  dashboard: {
    id: "header.dashboard",
    defaultMessage: "Dashboard",
  },
  usermanagement: {
    id: "header.usermanagement",
    defaultMessage: "Gebruikersbeheer",
  },
  users: {
    id: "header.users",
    defaultMessage: "Gebruikers",
  },
  usergroups: {
    id: "header.usergroups",
    defaultMessage: "Gebruikersgroepen",
  },
  organizations: {
    id: "header.organizations",
    defaultMessage: "Organisaties",
  },
  ssogroups: {
    id: "header.ssogroups",
    defaultMessage: "SSO-profielen",
  },
  profielen: {
    id: "header.profielen",
    defaultMessage: "Profielen",
  },
  gemeenten: {
    id: "header.gemeenten",
    defaultMessage: "Gemeenten",
  },
  crab: {
    id: "header.crab",
    defaultMessage: "CRAB-Adressen",
  },
  permissies: {
    id: "header.permissies",
    defaultMessage: "Permissies",
  },
  overview: {
    id: "header.overview",
    defaultMessage: "Overzicht",
  },
  statistics: {
    id: "header.statistics",
    defaultMessage: "Statistieken",
  },
  unknownappdisplayname: {
    id: "sidebar.unknownappdisplayname",
    defaultMessage: "Unknown app",
  },
  dailyappdisplayname: {
    id: "sidebar.dailyappdisplayname",
    defaultMessage: "Daily",
  },
  loggerappdisplayname: {
    id: "sidebar.loggerappdisplayname",
    defaultMessage: "Audit",
  },
  dashboardappdisplayname: {
    id: "sidebar.dashboardappdisplayname",
    defaultMessage: "Dashboard",
  },
  dailyappdisplayname: {
    id: "sidebar.policeappdisplayname",
    defaultMessage: "Daily",
  },
  municipalityappdisplayname: {
    id: "sidebar.municipalityappdisplayname",
    defaultMessage: "Legger",
  },
  "viewer-adminappdisplayname": {
    id: "sidebar.viewer-adminappdisplayname",
    defaultMessage: "Studio Manager",
  },
  "publication-viewerappdisplayname": {
    id: "sidebar.publication-viewerappdisplayname",
    defaultMessage: "Studio Viewer",
  },
});
